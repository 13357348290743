exports.components = {
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-actuarial-consulting-js": () => import("./../../../src/pages/ActuarialConsulting.js" /* webpackChunkName: "component---src-pages-actuarial-consulting-js" */),
  "component---src-pages-blogs-actuarial-perspective-js": () => import("./../../../src/pages/blogs/ActuarialPerspective.js" /* webpackChunkName: "component---src-pages-blogs-actuarial-perspective-js" */),
  "component---src-pages-blogs-enterprise-risk-js": () => import("./../../../src/pages/blogs/EnterpriseRisk.js" /* webpackChunkName: "component---src-pages-blogs-enterprise-risk-js" */),
  "component---src-pages-blogs-hr-strategy-js": () => import("./../../../src/pages/blogs/HrStrategy.js" /* webpackChunkName: "component---src-pages-blogs-hr-strategy-js" */),
  "component---src-pages-blogs-js": () => import("./../../../src/pages/blogs.js" /* webpackChunkName: "component---src-pages-blogs-js" */),
  "component---src-pages-blogs-roleofactuaries-js": () => import("./../../../src/pages/blogs/roleofactuaries.js" /* webpackChunkName: "component---src-pages-blogs-roleofactuaries-js" */),
  "component---src-pages-blogs-underwriting-scoring-model-js": () => import("./../../../src/pages/blogs/UnderwritingScoringModel.js" /* webpackChunkName: "component---src-pages-blogs-underwriting-scoring-model-js" */),
  "component---src-pages-business-consulting-js": () => import("./../../../src/pages/BusinessConsulting.js" /* webpackChunkName: "component---src-pages-business-consulting-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-csm-engine-js": () => import("./../../../src/pages/CSMEngine.js" /* webpackChunkName: "component---src-pages-csm-engine-js" */),
  "component---src-pages-data-mining-js": () => import("./../../../src/pages/DataMining.js" /* webpackChunkName: "component---src-pages-data-mining-js" */),
  "component---src-pages-employee-benefits-js": () => import("./../../../src/pages/EmployeeBenefits.js" /* webpackChunkName: "component---src-pages-employee-benefits-js" */),
  "component---src-pages-features-js": () => import("./../../../src/pages/features.js" /* webpackChunkName: "component---src-pages-features-js" */),
  "component---src-pages-i-ifrs-17-js": () => import("./../../../src/pages/i-IFRS17.js" /* webpackChunkName: "component---src-pages-i-ifrs-17-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-machine-learning-ai-js": () => import("./../../../src/pages/MachineLearningAI.js" /* webpackChunkName: "component---src-pages-machine-learning-ai-js" */),
  "component---src-pages-product-js": () => import("./../../../src/pages/product.js" /* webpackChunkName: "component---src-pages-product-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-servicesix-js": () => import("./../../../src/pages/servicesix.js" /* webpackChunkName: "component---src-pages-servicesix-js" */),
  "component---src-pages-technology-solutions-js": () => import("./../../../src/pages/TechnologySolutions.js" /* webpackChunkName: "component---src-pages-technology-solutions-js" */),
  "component---src-pages-un-limit-js": () => import("./../../../src/pages/UnLimit.js" /* webpackChunkName: "component---src-pages-un-limit-js" */),
  "component---src-pages-underwriting-score-model-js": () => import("./../../../src/pages/UnderwritingScoreModel.js" /* webpackChunkName: "component---src-pages-underwriting-score-model-js" */)
}

